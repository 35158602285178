<template>
  <div>
      <a-result
      status="403"
      title="development"
      sub-title="Sorry, you are not authorized to access this page."
    >
    </a-result>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>